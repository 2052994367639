import {
  Carousel as MCarousel,
  CarouselProps as MCarouselProps,
} from "@mantine/carousel";

import type { FCWithChildren } from "koljobs-types";

import { twConfig } from "@/helpers/getTailwindConfig";
import LeftIcon from "@/icons/left.svg";

const horizontalProps: MCarouselProps = {
  slideSize: "33.333333%",
  slideGap: "md",
  align: "start",
  styles: {
    root: {
      padding: `0 ${twConfig.theme.space["10"]}`,
    },
    viewport: {
      padding: `${twConfig.theme.space["2.5"]} 0`,
    },
    control: {
      border: 0,
      boxShadow: "none",
      outlineColor: "transparent !important",
    },
  },
  breakpoints: [{ maxWidth: "xl", slideSize: "50%" }],
  nextControlIcon: <LeftIcon className="rotate-180 text-gray-400" />,
  previousControlIcon: <LeftIcon className="text-gray-400" />,
};

const verticalProps: MCarouselProps = {
  withIndicators: true,
  slideSize: "100%",
  slideGap: "md",
  align: "center",
  withControls: false,
  styles: {
    root: {
      paddingBottom: twConfig.theme.space["10"],
    },
    viewport: {
      padding: twConfig.theme.space["2.5"],
    },
    indicator: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: `${twConfig.theme.colors.gray["300"]} !important`,

      "&[data-active]": {
        backgroundColor: `${twConfig.theme.colors.primary} !important`,
      },
    },
  },
};

type CarouselProps = FCWithChildren<{
  alignment?: "horizontal" | "vertical";
}> & {
  Slide: FCWithChildren;
};

export const Carousel: CarouselProps = ({
  children,
  alignment = "horizontal",
}) => {
  return (
    <MCarousel
      loop
      {...(alignment === "horizontal" ? horizontalProps : verticalProps)}
    >
      {children}
    </MCarousel>
  );
};

Carousel.Slide = ({ children }) => (
  <MCarousel.Slide>{children}</MCarousel.Slide>
);
