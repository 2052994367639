import Image from "next/legacy/image";

import { ListSectionContainer } from "@/features/home/components/ListSectionContainer";
import { UnderlinedSectionTitle } from "@/features/home/components/UnderlinedSectionTitle";

const steps = [
  { label: "註冊帳戶", name: "step1" },
  { label: "完善個人資料", name: "step2" },
  { label: "商戶刊登通告/KOL申請通告", name: "step3" },
  { label: "成功配對(查看隱藏資料)", name: "step4" },
];

export default function WorkItWorksSection() {
  return (
    <ListSectionContainer>
      <UnderlinedSectionTitle centered>如何運作</UnderlinedSectionTitle>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {steps.map(({ label, name }, index) => (
          <div className="flex flex-col items-center text-center" key={index}>
            <div className="relative h-44 w-44 2xl:h-56 2xl:w-56">
              <Image
                alt={name}
                src={`/images/home/howItWorks/${name}.png`}
                width={180}
                height={180}
                layout="responsive"
                unoptimized
                priority
              />
            </div>
            <div className="text-base text-gray-900 lg:text-lg">{label}</div>
          </div>
        ))}
      </div>
    </ListSectionContainer>
  );
}
