export const ModelCardSkeleton = () => {
  return (
    <div className="relative flex flex-col rounded-lg bg-white">
      <div className="pointer-events-none block aspect-[4/5] animate-pulse rounded-t-lg bg-slate-200"></div>
      <div className="pointer-events-none flex animate-pulse flex-col items-center justify-center space-y-4 py-4 px-4">
        <span className="h-4 w-full rounded bg-slate-200"></span>
        {/* <span className="h-4 bg-slate-200 rounded w-full"></span> */}
        <span className="h-4 w-full rounded bg-slate-200"></span>
      </div>
    </div>
  );
};
