import { useRouter } from "next/router";
import { useMemo } from "react";

export function useRequestQuery<WHERE, ORDER_BY>() {
  const { query, push } = useRouter();

  const queryObject = useMemo<{ where: WHERE; orderBy: ORDER_BY }>(() => {
    const { where, orderBy } = query;
    const whereObject = JSON.parse(decodeURI((where ?? "{}") as string));
    const orderByObject = JSON.parse(decodeURI((orderBy ?? "{}") as string));
    return { where: whereObject, orderBy: orderByObject };
  }, [query]);

  const setWhere = (where: WHERE) => {
    setRequestQuery({ ...queryObject, where });
  };

  const setOrderBy = (orderBy: ORDER_BY) => {
    setRequestQuery({ ...queryObject, orderBy });
  };

  const setRequestQuery = (requestQuery: {
    where?: WHERE;
    orderBy?: ORDER_BY;
  }) => {
    const { where = {}, orderBy = [] } = requestQuery;
    const whereEncodedJson = encodeURI(JSON.stringify(where));
    const orderByEncodedJson = encodeURI(JSON.stringify(orderBy));

    push(
      {
        query: {
          ...query,
          where: whereEncodedJson,
          orderBy: orderByEncodedJson,
          // reset to page 1 if page exists
          ...(query?.page && { page: 1 }),
        },
      },
      undefined,
      { shallow: true },
    );
  };

  const clearRequestQuery = () => {
    const { where, orderBy, ...rest } = query;
    push({ query: rest }, undefined, { shallow: true });
  };

  return {
    setRequestQuery,
    setWhere,
    setOrderBy,
    clearRequestQuery,
    query: queryObject,
  };
}
