import Link, { LinkProps } from "next/link";

import type { FCWithChildren } from "koljobs-types";

type LinkWrapperProps = { href?: string; onClick?: () => void } & Omit<
  LinkProps,
  "href"
>;

export const BlurLinkWrapper: FCWithChildren<LinkWrapperProps> = ({
  children,
  href,
  onClick,
  ...props
}) => {
  if (!href) {
    return (
      <div className="cursor-pointer blur-sm" onClick={onClick}>
        {children}
      </div>
    );
  }

  return (
    <Link href={href} passHref {...props}>
      {children}
    </Link>
  );
};
