import clsx from "clsx";

import type { FCWithChildren } from "koljobs-types";

import { Title, TitleProps } from "@/features/common/components/Title";

export const UnderlinedSectionTitle: FCWithChildren<{
  centered?: boolean;
  titleProps?: TitleProps;
}> = ({ centered, titleProps, children }) => {
  return (
    <div
      className={clsx(
        "flex flex-col space-y-4",
        centered ? "items-center" : "items-start",
      )}
    >
      <Title size="h2" className="font-normal text-gray-900" {...titleProps}>
        {children}
      </Title>
      <div className="bg-primary h-1 w-14 rounded-full"></div>
    </div>
  );
};
