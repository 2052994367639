import { Flex } from "@mantine/core";
import Image from "next/legacy/image";
import { useRouter } from "next/router";

import { formatNumber } from "koljobs-helpers";

import { useSubscriberAuth } from "@/features/businessSubscription/hooks/useSubscriberAuth";
import { BlurLinkWrapper } from "@/features/common/components/BlurLinkWrapper";
import { CloudinaryImage } from "@/features/common/components/CloudinaryImage";
import { jobTypes } from "@/features/job/const/jobTypes";
import { SaveModelButton } from "@/features/model/components/modeList/SaveModelButton";
import { ModelFragment } from "@/generated/graphql-hooks";

export const PREVIEW_INSTAGRAM_FOLLOWER_COUNT = "previewInstagramFollowerCount";

type ModelCardProps = {
  isBlurred?: boolean;
  isSaved?: boolean;
} & ModelFragment;

export const ModelCard = ({
  id,
  username,
  height,
  weight,
  instagram,
  instagramFollowerCount,
  avatarPublicId,
  isBlurred,
  prefJobType,
  isSaved = false,
}: ModelCardProps) => {
  const { isSubscriber, subscriberCall } = useSubscriberAuth();
  const { query } = useRouter();

  return (
    <BlurLinkWrapper
      href={`/kol/${id}/${username}`}
      prefetch={false}
      {...(isBlurred && {
        href: null,
        onClick: () => subscriberCall(null),
      })}
    >
      <div className="shadow-model-card group relative flex h-full flex-col overflow-hidden rounded-3xl bg-white">
        <div className="relative block aspect-[4/5] overflow-hidden rounded-t-lg bg-slate-200 group-hover:opacity-80">
          <CloudinaryImage
            alt={username}
            className="rounded-t-lg"
            publicId={avatarPublicId}
          />
        </div>
        <div className="flex flex-1 flex-col items-start justify-start space-y-2 px-3 pt-4 pb-5 text-sm font-light text-gray-700 group-hover:opacity-80">
          <div className="flex w-full items-center justify-between gap-2">
            <span className="w-full truncate text-lg font-medium text-gray-900">
              {username}
            </span>

            <SaveModelButton modelId={id} isSaved={isSaved} />
          </div>

          {!!instagram && !!instagramFollowerCount && (
            <Flex className="space-x-1">
              <Image
                layout="fixed"
                objectFit="cover"
                height={18}
                width={18}
                src="/images/ig.png"
                alt="ig"
              />
              <span className="font-medium">
                {formatNumber(instagramFollowerCount)}
              </span>

              <span>followers</span>
            </Flex>
          )}

          <span className="w-full truncate text-xs">
            {prefJobType?.map((jobType) => jobTypes[jobType])?.join(", ") ||
              "-"}
          </span>

          <span>
            {height || "-"}cm / {weight || "-"}kg
          </span>
        </div>
      </div>
    </BlurLinkWrapper>
  );
};
