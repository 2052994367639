import { ErrorRetry } from "@/features/common/components/ErrorRetry";
import { ActiveModelCardContainer } from "@/features/home/components/ActiveModelCardContainer";
import { EmptySectionItem } from "@/features/home/components/EmptySectionItem";
import { ListSectionContainer } from "@/features/home/components/ListSectionContainer";
import { ModelCard } from "@/features/model/components/ModelCard";
import { ModelCardSkeleton } from "@/features/model/components/ModelCardSkeleton";
import { SortOrder, useModelsQuery } from "@/generated/graphql-hooks";
import { getPublicConfig } from "@/helpers/getPublicConfig";

const {
  features: {
    home: { maxModels },
  },
} = getPublicConfig();

export default function ActiveModelsSection() {
  const {
    data: { models } = {},
    isLoading,
    error,
    refetch,
  } = useModelsQuery({
    where: {
      ...Object.fromEntries(
        ["height", "weight"].map((field) => [field, { gt: 0 }]),
      ),
    },
    orderBy: { order: SortOrder.Desc },
    take: maxModels,
  });

  return (
    <ListSectionContainer title="活躍KOL" path="/kol">
      <div className="grid grid-cols-2 justify-center gap-10 p-4 lg:grid-cols-4 xl:gap-16">
        {!!isLoading &&
          Array.from(Array(4)).map((_, index) => (
            <ActiveModelCardContainer key={index}>
              <ModelCardSkeleton />
            </ActiveModelCardContainer>
          ))}
        {!isLoading && !!error && (
          <div className="col-span-full">
            <ErrorRetry refetch={refetch} error={error}/>
          </div>
        )}
        {!isLoading && !models?.length && !error && (
          <div className="col-span-full mt-10">
            <EmptySectionItem imgSrc="/images/user/model.png" title="KOL" />
          </div>
        )}
        {!isLoading &&
          models?.map((model) => (
            <ActiveModelCardContainer key={model.id}>
              <ModelCard {...model} />
            </ActiveModelCardContainer>
          ))}
      </div>
    </ListSectionContainer>
  );
}
