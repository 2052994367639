import { useMemo } from "react";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { useConfirmDialogContext } from "@/features/common/contexts/useConfirmDialogContext";
import {
  DialogViewEnum,
  useDialogContext,
} from "@/features/common/contexts/useDialogContext";
import {
  SubscriptionStatusEnum,
  UserRoleEnum,
} from "@/generated/graphql-hooks";

export const useSubscriberAuth = () => {
  const { openConfirmDialog } = useConfirmDialogContext();
  const { openDialog } = useDialogContext();
  const { user } = useUserContext();

  const isSubscriber = useMemo(() => {
    if (user?.roleData?.__typename !== "Business") {
      return false;
    }

    return (
      user.roleData.subscription?.status === SubscriptionStatusEnum.Subscribed
    );
  }, [user]);

  const subscriberCall = (fn: () => Promise<void> | void) => {
    if (user?.role !== UserRoleEnum.Business) {
      openConfirmDialog({
        content: "此功能只限VIP商戶使用",
        onConfirm: null,
        hideActionButtons: true,
      });
      return;
    }

    if (!isSubscriber) {
      openDialog({ view: DialogViewEnum.JoinSubscriptionDialog });
      return;
    }

    fn();
  };

  return {
    isSubscriber,
    subscriberCall,
  };
};
