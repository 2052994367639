import clsx from "clsx";
import { MouseEvent, ReactNode, useCallback } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";

import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { IconButton } from "@/features/common/components/IconButton";
import {
  useConfirmDialogContext,
  withConfirmDialog,
} from "@/features/common/contexts/useConfirmDialogContext";
import { useRequestQuery } from "@/features/common/hooks/useRequestQuery";
import {
  ModelQuery,
  ModelsQuery,
  useCreateSaveModelMutation,
  useDeleteSaveModelMutation,
} from "@/generated/graphql-hooks";
import SaveModelIcon from "@/icons/save_model.svg";

type SaveModelButtonProps = {
  isSaved: boolean;
  modelId: string;
  icon?: ReactNode;
};
export const SaveModelButton = withConfirmDialog(
  ({ modelId, isSaved, icon }: SaveModelButtonProps) => {
    const queryClient = useQueryClient();
    const { query } = useRequestQuery();
    const { user } = useUserContext();
    const { openConfirmDialog } = useConfirmDialogContext();

    const optimisticUpdate = useCallback(async () => {
      const modelListQueryKey = queryClient
        .getQueryCache()
        .find(["Models", query], { exact: false, stale: true })?.queryKey;
      const modelQueryKey = queryClient
        .getQueryCache()
        .find(["Model", { where: { id: modelId } }])?.queryKey;

      if (modelListQueryKey) {
        await queryClient.cancelQueries({
          queryKey: modelListQueryKey,
        });

        queryClient.setQueryData<ModelsQuery>(
          modelListQueryKey,
          (oldModels) => ({
            models: oldModels.models.map((model) => ({
              ...model,
              isSaved: model.id === modelId ? !isSaved : model.isSaved,
            })),
          }),
        );
      } else {
        await queryClient.cancelQueries({
          queryKey: modelQueryKey,
        });

        queryClient.setQueryData<ModelQuery>(modelQueryKey, (oldModelData) => ({
          model: {
            ...oldModelData.model,
            isSaved: !oldModelData.model.isSaved,
          },
        }));
      }

      return { previousIsSaved: !isSaved, modelListQueryKey, modelQueryKey };
    }, [queryClient, modelId, query, isSaved]);

    const { mutate: createSaveModel, isLoading: isSavingModel } =
      useCreateSaveModelMutation({
        onMutate: async () => {
          return optimisticUpdate();
        },
        onSuccess: () => {
          toast.success("已成功收藏KOL");
        },
        onError: (_, __, context) => {
          toast.error("發生錯誤，請稍後再試");

          queryClient.setQueryData(
            context.modelListQueryKey ?? context.modelQueryKey,
            {
              isSaved: context.previousIsSaved,
            },
          );
        },
        onSettled: (_, __, ___, context) => {
          queryClient.invalidateQueries(
            context.modelListQueryKey ?? context.modelQueryKey,
          );
        },
      });

    const { mutate: deleteSaveModel, isLoading: isUnsavingModel } =
      useDeleteSaveModelMutation({
        onMutate: async () => {
          return optimisticUpdate();
        },
        onSuccess: () => {
          toast.success("已取消收藏KOL");
        },
        onError: (_, __, context) => {
          toast.error("發生錯誤，請稍後再試");

          queryClient.setQueryData(
            context.modelListQueryKey ?? context.modelQueryKey,
            {
              isSaved: context.previousIsSaved,
            },
          );
        },
        onSettled: (_, __, ___, context) => {
          queryClient.invalidateQueries(
            context.modelListQueryKey ?? context.modelQueryKey,
          );
        },
      });

    const handleClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!user) {
          openConfirmDialog({
            content: "請先登入/註冊，才能使用此功能",
            hideActionButtons: true,
          });

          return;
        }

        if (isSaved) {
          deleteSaveModel({ modelId });
        } else {
          createSaveModel({ modelId });
        }
      },
      [
        deleteSaveModel,
        createSaveModel,
        modelId,
        isSaved,
        user,
        openConfirmDialog,
      ],
    );

    return (
      <IconButton
        onClick={handleClick}
        disabled={isUnsavingModel || isSavingModel}
      >
        {icon ?? (
          <SaveModelIcon
            className={clsx(isSaved ? "text-gray-700" : "text-gray-200")}
          />
        )}
      </IconButton>
    );
  },
);
