import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { Carousel } from "@/features/common/components/Carousel";
import { ErrorRetry } from "@/features/common/components/ErrorRetry";
import { EmptySectionItem } from "@/features/home/components/EmptySectionItem";
import { ListSectionContainer } from "@/features/home/components/ListSectionContainer";
import { JobCard } from "@/features/job/components/jobList/JobCard";
import { JobCardSkeleton } from "@/features/job/components/jobList/JobCardSkeleton";
import { useJobDetailsDialog } from "@/features/job/hooks/useJobDetailsDialog";
import {
  JobFragment,
  JobStatusEnum,
  SortOrder,
  useJobsQuery,
} from "@/generated/graphql-hooks";
import { getPublicConfig } from "@/helpers/getPublicConfig";

const {
  features: {
    home: { maxJobs },
  },
} = getPublicConfig();

export default function LatestJobsSection() {
  const {
    data: { jobs } = {},
    isLoading,
    error,
    refetch,
  } = useJobsQuery({
    where: {
      status: {
        equals: JobStatusEnum.Opened,
      },
    },
    take: maxJobs,
    orderBy: { createdAt: SortOrder.Desc },
  });

  useJobDetailsDialog({ jobs, isLoading });

  const threeJobsPerGroup = useMemo(
    () =>
      jobs?.reduce(
        (r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r,
        [],
      ),
    [jobs],
  );

  return (
    <ListSectionContainer title="最新通告" path="/job" bg="bg-gray-50">
      {isLoading && (
        <div className="space-y-3 py-5">
          {Array.from(Array(3)).map((_, index) => (
            <JobCardSkeleton
              key={index}
              containerClassName={twMerge(index > 0 && "")}
            />
          ))}
        </div>
      )}

      {!isLoading && !!error && <ErrorRetry refetch={refetch} error={error}/>}

      {!isLoading && !jobs?.length && !error && (
        <div className="mt-10">
          <EmptySectionItem imgSrc="/images/job.png" title="通告" />
        </div>
      )}

      {!isLoading && jobs?.length && (
        <div className="hidden lg:block">
          <Carousel>
            {jobs?.map((job) => (
              <Carousel.Slide key={job.id}>
                <JobCard job={job} tinyLayout />
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      )}

      {!isLoading && jobs?.length && (
        <div className="lg:hidden">
          <Carousel alignment="vertical">
            {threeJobsPerGroup?.map((jobs, index) => (
              <Carousel.Slide key={index}>
                <div className="space-y-3">
                  {jobs.map((job: JobFragment) => (
                    <JobCard job={job} tinyLayout key={job.id} />
                  ))}
                </div>
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      )}
    </ListSectionContainer>
  );
}
