import Link from "next/link";

import { getPublicConfig } from "@/helpers/getPublicConfig";
import AppStoreIcon from "@/icons/app_store.svg";
import PlayStoreIcon from "@/icons/play_store.svg";

const { appLink } = getPublicConfig();

export const AppDownloadBadge = ({ os }: { os: "ios" | "android" }) => {
  return (
    <Link target="_blank" href={os === "ios" ? appLink.ios : appLink.android}>
      {os === "ios" ? <AppStoreIcon /> : <PlayStoreIcon />}
    </Link>
  );
};
