import Image from "next/legacy/image";
import { useRouter } from "next/router";

import { Button } from "@/features/common/components/Button";
import { Title } from "@/features/common/components/Title";
import { ListSectionContainer } from "@/features/home/components/ListSectionContainer";
import { useUserContext } from "@/features/auth/contexts/useUserContext";
import { UserRoleEnum } from "@/generated/graphql-hooks";

export default function JoinUsNowSection() {
  const router = useRouter();
  const {user} = useUserContext();

  return (
    <ListSectionContainer className="space-y-32 lg:space-y-20 xl:space-y-48">
      <div className="bg-secondary mt-24 grid gap-0 rounded-lg p-5 lg:mt-8 lg:grid-cols-2 xl:mt-40 xl:p-10">
        <div className="relative -mt-28 flex-1 lg:-mt-14 xl:-mt-44">
          <Image
            className="rounded-xl"
            alt="business_banner"
            src="/images/home/joinUsNow/business.jpg"
            layout="responsive"
            width={1080}
            height={629}
            objectFit="cover"
          />
        </div>
        <div className="flex flex-col justify-center space-y-3 pt-5 lg:pl-7 lg:pt-0 xl:pl-10">
          <Title size="h2" className="text-gray-900">
            招募KOL助你推廣業務
          </Title>
          <p className="text-base text-gray-900">
            你可以刊登通告讓平台的KOL自行申請，同時也可於「管理通告」頁面邀請他們，以最快速度招募KOL。KOL的個人檔案頁面一目瞭然，方便你挑選合適人才，無需經過多重交接，將宣傳效益極大化。
          </p>
          <Button
            variant="secondary"
            className="hover:!bg-secondary w-fit !bg-white !px-6 !py-2 font-medium sm:py-3 sm:!text-xl"
            onClick={() => router.push("/kol")}
          >
            找KOL
          </Button>
        </div>
      </div>

      <div className="bg-secondary grid gap-0 rounded-lg p-5 lg:grid-cols-2 xl:p-10">
        <div className="relative -mt-28 flex-1 lg:hidden">
          <Image
            className="rounded-xl"
            alt="kol_banner"
            src="/images/home/joinUsNow/kol.jpg"
            layout="responsive"
            width={1080}
            height={630}
            objectFit="cover"
            priority
          />
        </div>
        <div className="flex flex-col justify-center space-y-3 pt-5 lg:pr-7 lg:pt-0 xl:pr-10">
          <Title size="h2" className="text-gray-900">
            你事業的長遠發展
          </Title>
          <p className="text-base text-gray-900">
            活躍於社群媒體的你，在分享生活的同事，不妨運用自己的影響力，賺取多份被動收入，開拓更多業配機會。平台還可以令你提升曝光率，心動不如行動，加入我們的大家庭吧！
          </p>
            <Button
              className="w-fit !px-6 !py-2 font-medium sm:py-3 sm:!text-xl"
              onClick={() =>
                router.push(
                  user?.role === UserRoleEnum.Business
                    ? "/business/createJob"
                    : "/job",
                )
              }
            >
              {user?.role === UserRoleEnum.Business ? "刊登通告" : "接通告"}
            </Button>
        </div>
        <div className="relative -mt-14 hidden flex-1 lg:block xl:-mt-44">
          <Image
            className="rounded-xl"
            alt="kol_banner"
            src="/images/home/joinUsNow/kol.jpg"
            layout="responsive"
            width={1080}
            height={630}
            objectFit="cover"
            priority
          />
        </div>
      </div>
    </ListSectionContainer>
  );
}
