import clsx from "clsx";
import { useRouter } from "next/router";

import type { FCWithChildren } from "koljobs-types";

import { Container } from "@/features/common/components/Container";
import { Title } from "@/features/common/components/Title";

export const ListSectionContainer: FCWithChildren<
  Partial<Record<"title" | "path" | "className" | "bg", string>>
> = ({ children, className, title, path, bg = "bg-white" }) => {
  const { push } = useRouter();

  return (
    <section className={bg}>
      <Container className={clsx(`space-y-4 py-10`, className)}>
        {!!path && (
          <div className="flex items-end justify-between">
            <Title size="h2" className="font-normal  text-gray-900">
              {title}
            </Title>
            <div className="flex items-center text-base text-gray-500">
              <button className="leading-6" onClick={() => push(path)}>
                查看更多
              </button>
            </div>
          </div>
        )}

        {children}
      </Container>
    </section>
  );
};
