import Image from "next/legacy/image";

import { AppDownloadBadge } from "@/features/common/components/AppDownloadBadge";
import { Container } from "@/features/common/components/Container";
import { Title } from "@/features/common/components/Title";

export default function BannerSection() {
  return (
    <div className="relative inline-flex h-[550px] w-full items-center justify-start sm:h-[40rem]">
      <Image
        alt="banner"
        src="/images/home/hero_banner.jpg"
        layout="fill"
        objectFit="cover"
        priority
        // override the default loader, keep the same image quality for web & mobile
        loader={({ src }) => src}
      />
      <Container className="z-10 space-y-3 font-medium text-white">
        <Title className="text-shadow w-60 !leading-[3rem] text-white sm:w-96 sm:!leading-[4rem]">
          KOL在這裡尋找你的工作機遇
        </Title>
        <Title
          className="text-shadow !leading-7 text-white sm:!leading-8"
          size="h3"
        >
          僱主在此挑選你的理想KOL
        </Title>
        <div className="flex gap-3 pt-4">
          <AppDownloadBadge os="ios" />
          <AppDownloadBadge os="android" />
        </div>
      </Container>
    </div>
  );
}
