import ActiveModelsSection from "@/features/home/components/ActiveModelsSection";
import BannerSection from "@/features/home/components/BannerSection";
import BusinessCommentSection from "@/features/home/components/BusinessCommentSection";
import HowItWorksSection from "@/features/home/components/HowItWorksSection";
import JoinUsNowSection from "@/features/home/components/JoinUsNowSection";
import LatestJobsSection from "@/features/home/components/LatestJobsSection";
import PopularJobsSection from "@/features/home/components/PopularJobsSection";
import WhyUsSection from "@/features/home/components/WhyUsSection";
import { SiteLayout } from "@/features/layout/SiteLayout";

const Home = () => {
  return (
    <div className="bg-white pb-10">
      <BannerSection />

      <PopularJobsSection />

      <LatestJobsSection />

      <ActiveModelsSection />

      <JoinUsNowSection />

      <WhyUsSection />

      <BusinessCommentSection />

      <HowItWorksSection />
    </div>
  );
};

export default Home;

Home.getLayout = function getLayout(page) {
  return <SiteLayout>{page}</SiteLayout>;
};
