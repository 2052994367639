import Image from "next/legacy/image";
import Link from "next/link";

import { Title } from "@/features/common/components/Title";
import { ListSectionContainer } from "@/features/home/components/ListSectionContainer";
import { UnderlinedSectionTitle } from "@/features/home/components/UnderlinedSectionTitle";
import { jobTypeFilledIcons, jobTypes } from "@/features/job/const/jobTypes";
import { JobTypeEnum } from "@/generated/graphql-hooks";

const popularJobs = {
  [JobTypeEnum.IgPost]: "寫好寫滿",
  [JobTypeEnum.AdFilm]: "生動有趣",
  [JobTypeEnum.ProductTrial]: "貼地推介",
  [JobTypeEnum.AdPhoto]: "拍攝美照",
  [JobTypeEnum.Restaurant]: "飲飲食食",
  [JobTypeEnum.Treatment]: "效果顯著",
};

export default function PopularJobsSection() {
  return (
    <ListSectionContainer>
      <div className="grid gap-10 sm:grid-cols-2">
        <div className="flex items-center">
          <div className="relative flex-1">
            <Image
              alt="search"
              src="/images/home/search_job.png"
              layout="responsive"
              width={562}
              height={382}
              objectFit="cover"
              priority
            />
          </div>
        </div>
        <div className="flex flex-col justify-center py-2">
          <UnderlinedSectionTitle>工作類型</UnderlinedSectionTitle>
          <div className="grid grid-cols-2 gap-5 py-5">
            {Object.entries(popularJobs).map(([key, content], index) => {
              const Icon = jobTypeFilledIcons[key];
              return (
                <Link
                  href={`/job?where=${encodeURIComponent(
                    JSON.stringify({ jobType: { equals: key } }),
                  )}`}
                  key={index}
                  className="group relative marker:overflow-hidden"
                >
                  <div className="flex items-center space-x-3 text-base text-gray-700 group-hover:opacity-80">
                    <div className="shadow-primary/20 rounded-full p-3 shadow-lg">
                      <Icon width={30} height={30} />
                    </div>

                    <div>
                      <div className="font-medium">{jobTypes[key]}</div>
                      <div className="text-sm text-gray-500">{content}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </ListSectionContainer>
  );
}
