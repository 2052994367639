import type { FCWithChildren } from "koljobs-types";

export const ActiveModelCardContainer: FCWithChildren = ({ children }) => {
  return (
    <div className="relative">
      <div className="absolute h-full w-full rotate-6 rounded-3xl bg-[#FFF2DE]"></div>
      {children}
    </div>
  );
};
