import { minFee } from "koljobs-constants";

// Using simpliest solution on
// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export const formatNumber = (num: number) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
  // Not supported
  // if (num < 10000) return num.toLocaleString();

  // return Intl.NumberFormat("zh-HK", {
  //   notation: "compact",
  //   maximumFractionDigits: 1,
  // }).format(num);
};

export const formatNumberToPercentage = (num: number) => {
  return `${num * 100}%`;

  // return Intl.NumberFormat("zh-HK", {
  //   style: "percent",
  // }).format(num);
};

export const getJobCreditCost = (price: number) => {
  if (price < 200) return minFee;
  if (price < 3000) return Math.ceil(price * 0.25);
  if (price < 10000) return Math.ceil(price * 0.2);
  return Math.ceil(price * 0.15);
};

export const hideEndOfIgAccountName = (
  accountName: string,
  numberOfCharsToHide: number = 3,
) => {
  return accountName.slice(0, -1 * numberOfCharsToHide) + "***";
};

export const extractPhoneNumberInfo = (phoneNumber: string) => {
  return {
    countryCode: parseInt(phoneNumber.slice(1, 4)),
    phoneNumber: phoneNumber.slice(4, phoneNumber.length),
  };
};
