import Image from "next/legacy/image";

import { getPublicConfig } from "@/helpers/getPublicConfig";

const {
  project: { siteName },
} = getPublicConfig();

export const EmptySectionItem = ({
  imgSrc,
  title,
}: {
  imgSrc: string;
  title: string;
}) => {
  return (
    <div className="space-y-2">
      <div className="flex justify-center">
        <Image
          alt={siteName}
          src={imgSrc}
          layout="fixed"
          width={250}
          height={250}
          unoptimized
        />
      </div>
      <div className="text-center text-lg text-gray-700">暫時沒有{title}</div>
    </div>
  );
};
