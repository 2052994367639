import Image from "next/legacy/image";

import { Title } from "@/features/common/components/Title";
import { ListSectionContainer } from "@/features/home/components/ListSectionContainer";
import { UnderlinedSectionTitle } from "@/features/home/components/UnderlinedSectionTitle";
import CloseQuotationIcon from "@/icons/close_quotation.svg";
import OpenQuotationIcon from "@/icons/open_quotation.svg";

export default function BusinessCommentSection() {
  return (
    <ListSectionContainer bg="bg-gray-50">
      <div className="items-center space-y-14 sm:pb-5 lg:flex lg:space-x-10 lg:space-y-0">
        <div className="flex-1 space-y-2 text-base text-gray-700">
          <div className="relative flex items-start">
            <div>
              <OpenQuotationIcon className="h-24 w-24" />
            </div>
            <div className="shadow-primary/20 -ml-20 mt-5 flex h-max space-x-3 rounded-lg bg-white p-5 shadow-lg sm:-ml-12">
              <div>
                <Image
                  className="rounded-full"
                  alt="business1"
                  src="/images/home/businessComment/business1.png"
                  layout="fixed"
                  width={70}
                  height={70}
                  objectFit="cover"
                  priority
                />
              </div>
              <div>
                <span className="text-primary font-medium">Mattew Wong</span>
                <span className="text-sm"> / 交友APP投資者</span>
                <p className="mt-1 text-sm">
                  這裏的KOL十分多樣化，我可以清晰、方便、快速地尋找適合我們的KOL。全自助、一站式的運作模式也令我的營銷方案達至省時、省力、省成本，作為商戶我十分推薦KOL
                  Jobs這個平台。
                </p>
              </div>
            </div>
          </div>

          <div className="relative flex items-end">
            <div className="shadow-primary/20 z-10 -mr-24 mt-5 flex h-max space-x-3 rounded-lg bg-white p-5 shadow-lg sm:-mr-6">
              <div>
                <Image
                  className="rounded-full"
                  alt="business2"
                  src="/images/home/businessComment/business2.png"
                  layout="fixed"
                  width={70}
                  height={70}
                  objectFit="cover"
                  priority
                />
              </div>
              <div className="text-sm">
                <span className="text-primary text-base font-medium">
                  Jason Cheung
                </span>
                <span> / 西式餐廳主管</span>
                <p>
                  平台十分容易使用，令我找到不少潛力KOL為我的業務作宣傳。KOL以親身經驗做推薦，較親近消費者，宣傳效果亦令我很滿意，KOL
                  Jobs將會是我做marketing的首選。
                </p>
              </div>
            </div>
            <div className="-mb-5">
              <CloseQuotationIcon />
            </div>
          </div>
        </div>

        <div className="flex-1 space-y-4">
          <UnderlinedSectionTitle>商戶的評價</UnderlinedSectionTitle>
          <p className="text-base text-gray-900">
            KOL
            Jobs善用科技，致力提升KOL與商戶的用戶體驗，讓整個配對流程暢通無阻，告別舊式的繁瑣事務。現在就來加入我們吧！
          </p>
        </div>
      </div>
    </ListSectionContainer>
  );
}
