import { Card, Flex, Text } from "@mantine/core";
import Image from "next/image";

import { ListSectionContainer } from "@/features/home/components/ListSectionContainer";
import { UnderlinedSectionTitle } from "@/features/home/components/UnderlinedSectionTitle";

const items = [
  {
    title: "安全、可信性高",
    description:
      "KOL Jobs耗資逾百萬，誓要打造全港最具規模的KOL平台。為防止濫用刊登通告功能，我們會在商戶刊登通告時抽取刊登費，避免惡意濫用平台的操作，KOL可以放心申請工作，因為這些都是發佈自真實商戶。",
    name: "security",
  },
  {
    title: "免費個人檔案",
    description:
      "KOL除了可以增加接業配的機會，還可以在平台上建立免費的檔案，列出各項想展示的資料，建立完美作品集。KOL還可以上載相片到公開相簿和隱藏相簿，隱藏相簿只會對你所申請通告的商戶開放查看權限。",
    name: "profile",
  },
  {
    title: "提升曝光率",
    description:
      "我們與很多商戶合作，網站瀏覽量很大佔有率來自需要KOL服務的商戶，註冊加入我們，相當於可以觸及大量不同行業的商戶，間接宣傳自己，為自己帶來更多工作機會，增加被動收入。",
    name: "exposure",
  },
];

export default function WhyUsSection() {
  return (
    <>
      <div className="absolute mt-[332px] hidden h-96 w-full bg-gray-50 md:flex"></div>
      <ListSectionContainer>
        <div className="mx-auto w-fit">
          <UnderlinedSectionTitle centered>
            KOL為什麼選擇我們?
          </UnderlinedSectionTitle>
        </div>

        <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
          {items.map(({ title, description, name }, index) => (
            <Card
              p="xs"
              radius="md"
              className="shadow-primary/20 rounded-lg shadow-lg"
              key={index}
            >
              <Card.Section className="h-[220px]">
                <Image
                  alt="security"
                  src={`/images/home/whyUs/${name}.jpg`}
                  className="h-full w-full object-cover"
                  height={220}
                  width={367}
                />
              </Card.Section>

              <Flex
                px={2}
                align="center"
                direction="column"
                className="-translate-y-5 px-4"
              >
                <div className="bg-primary h-10 w-10 rounded-full text-center font-medium leading-10 text-white">
                  {index + 1}
                </div>

                <Text
                  component="h3"
                  size="lg"
                  weight="bold"
                  className="my-4 text-gray-900"
                >
                  {title}
                </Text>

                <Text component="p" className="text-gray-900">
                  {description}
                </Text>
              </Flex>
            </Card>
          ))}
        </div>
      </ListSectionContainer>
    </>
  );
}
